<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Movie | Details  </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'Allmovies',params:{ MainCatg:'All'}}">Movies</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8" v-for="Data in RespDtlMovies" v-bind:key="Data.id">
                <div class="page-title-text">
                    <div class="row"><h5>{{Data.movie_name}}</h5></div><br>
                    <div class="row"><p class="card-text font-italic">{{Data.language}} / {{Data.year}} / {{Data.movie_type}}</p></div><br>
                </div>
                <div>
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-12 Thira profile-card-5">
                        <div class="card-img-block">
                          <img v-bind:src="Data.movie_image" alt="Image">
                        </div>
                        <p class="pre-formatted" v-if='Data.movie_desc !== ("" || null)' ><span v-html="Data.movie_desc"></span></p>    
                    </div>
                  </b-row>  
                </div>  
                <!-- Contact us section starts here  --> 
                <!-- Description Tabs starts here  -->
                <!-- Academy Photo Gallery starts here  -->
                <div class="contact-card">
                <b-tabs content-class="mt-3">
                    <b-tab title="Watch Trailer" active>
                      <div class="container">
                        <b-row>
                            <div class="col-lg-12 col-md-12" >
                            <span v-if='Data.trailer_url !== ("" || null)' class="w-100 p-3" v-html="Data.trailer_url"></span>
                            </div>                            
                        </b-row>
                      </div>                       
                    </b-tab>
                    <b-tab title="Watch Movie">
                    <div class="container">
                        <b-row>
                            <div class="col-lg-12 col-md-12" >
                                <span v-if='Data.trailer_url !== ("" || null)' class="w-100 p-3" v-html="Data.trailer_url"></span>
                            </div>                            
                        </b-row>
                    </div>
                    </b-tab>
                </b-tabs>
                </div>                  
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar" v-if="ShowSideCatg">
                <!-- sidebar Categories -->
                <SideCatgPerformers />
                <!-- <Side Recent Performers/> -->
                <SideRecentPerformers :key="componentKey"/>       
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>              
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer v-if="ShowSideCatg"/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'
import Lightbox from 'vue-my-photos'

Vue.component('lightbox', Lightbox);
Vue.use(Lightbox)

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"Movies",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Upcoming Movies | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },     
    data() {
      return {
        RespDtlMovies: [],
        componentKey: 0,
        ShowSideCatg: false,         
        }
      },     
    mounted()
    {
      this.ReadDtlMovies(this.$route.params.MovieId)
        console.log('Route:',this.$route.params.$MovieId)
    },
    methods: {
      ReadDtlMovies(RecToFetch){  
        const REQformData = new FormData();
        REQformData.append('seq_no', RecToFetch);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadDtlMovies',REQformData)
        .then((resp)=>{
        this.RespDtlMovies=resp.data.DtlMovies;
        this.ShowSideCatg=true;
      })
      },                    
    },
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: auto;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    float: center;
    width: 300px;
    padding: 10px 10px 10px 10px;
    height: auto;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: auto;
  /* overflow: hidden; */

}
.profile-card-5 .card-img-block img{
    float: left;
    width: 300px;
    margin-top:5px;
    padding: 10px 20px 20px 10px;
    max-height: 400px;
    border-radius: 50px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}

.profile-card-5 p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify; 
  /* display: inline-block; */
}
.tab-desc-formatted {
  white-space: pre-line;
  text-align: justify;
  max-height: 450px;
  overflow: scroll;  
  /* display: inline-block; */
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
.page-title-text h6 {
    margin-top: -25px;
    color:#e75480;
    font-size:14px;
    font-weight:300;
}
.page-title-text p {
    margin-top: -25px;
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}

.medica-video-card {
    margin-top:10px;
}

.contact-card {
    margin-top:25px;
    box-shadow:0 0 1px rgba(0,0,0,0.63);
    border-radius:5px;
}
.contact-card h5{
    margin-top:20px;
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.contact-card p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.contact-card .social-links a {
    font-size: 25px;
    display: inline-block;
    /* background: #1e4356; */
    color: #e75480;
    line-height: 1;
    padding: 12px 0;
    margin-right: 4px;
    margin-bottom:30px;
    border-radius: 50%;
    border: 2px solid #72afce;
    text-align: center;
    width: 50px;
    height: 50px;
    transition: 0.3s;
}

.contact-card .social-links a:hover {
    background: #e75480;
    color: #fff;
    text-decoration: none;
}

img {

  margin: 1px;
  border-radius: 15px;
  max-width: 450px;
  max-height: 400px;
}


</style>
